import { defineStore } from 'pinia';

import type { SurveyForm, SurveyFormData } from '~/composables/forms/useSurveyForm';

const KEY = 'surveyStore';

function wait(delay: number): Promise<true> {
    return new Promise((res) => {
        setTimeout(() => res(true), delay);
    });
}

export const useSurveyStore = defineStore('surveyStore', {
    state: () => ({
        formData: JSON.parse(localStorage.getItem(KEY) || 'null') as Partial<SurveyFormData>,
        savingState: 'success' as 'success' | 'pending' | 'error',
    }),
    actions: {
        save(payload: Partial<SurveyFormData>) {
            localStorage.setItem(KEY, JSON.stringify(payload));
            this.savingState = 'pending';
            this.savingState = 'success';
        },
        resetStorage() {
            localStorage.removeItem(KEY);
        },
    },
})
