import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47forgot_45password_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/forgot-password.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47dashboard_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/dashboard.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47qr_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/qr.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47reports_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/reports.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47index_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/index.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45in_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-in.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45out_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-out.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47survey_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/home/survey.vue?macro=true";
import { meta as _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47index_46vueMeta } from "/home/supplierportal/supplierportal-web/frontend2/pages/index.vue?macro=true";
export default [
  {
    name: "forgot-password",
    path: "/forgot-password",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/forgot-password.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47forgot_45password_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47forgot_45password_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "home-dashboard",
    path: "/home/dashboard",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/dashboard.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47dashboard_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47dashboard_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "home-qr",
    path: "/home/qr",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/qr.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47qr_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47qr_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/qr.vue").then(m => m.default || m)
  },
  {
    name: "home-reports",
    path: "/home/reports",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/reports.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47reports_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47reports_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/reports.vue").then(m => m.default || m)
  },
  {
    name: "home-stock-inventory",
    path: "/home/stock/inventory",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/index.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47index_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47index_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "home-stock-inventory-move-in",
    path: "/home/stock/inventory/move-in",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-in.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45in_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45in_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-in.vue").then(m => m.default || m)
  },
  {
    name: "home-stock-inventory-move-out",
    path: "/home/stock/inventory/move-out",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-out.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45out_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47stock_47inventory_47move_45out_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/stock/inventory/move-out.vue").then(m => m.default || m)
  },
  {
    name: "home-survey",
    path: "/home/survey",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/home/survey.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47survey_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47home_47survey_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/home/survey.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    file: "/home/supplierportal/supplierportal-web/frontend2/pages/index.vue",
    children: [],
    meta: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47index_46vueMeta,
    alias: _47home_47supplierportal_47supplierportal_45web_47frontend2_47pages_47index_46vueMeta?.alias || [],
    component: () => import("/home/supplierportal/supplierportal-web/frontend2/pages/index.vue").then(m => m.default || m)
  }
]