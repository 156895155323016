export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appAssetsPath = {}

export const appCdnURL = ""

export const appHead = {"meta":[],"link":[],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appKeepalive = false