import { useAuth } from "~/store/auth";

export default function useAuthFetch<TData>(url: string, opts?: any | undefined | null) : Promise<TData> {
  const userStore = useAuth()
  const jwt = userStore.getToken
  return $fetch(url, {
    ...(opts ? opts : {}),
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
}