import { defineStore } from 'pinia';
import { groupBy } from 'lodash-es';

interface Item {
    bagId: string,
    productId: string,
    composition: string,
    color: string,
    finish: string,
    weight: number,
    updatedAt: Date,
    /**
     * true - in stock, false - moved out, undefined - created
     */
    inStock: boolean | undefined,
    width: number,
    gsm: number,
}

interface ItemGroup {
    productId: string,
    composition: string,
    color: string,
    finish: string,
    totalItems: number,
    totalWeight: number,
    updatedAt: Date,
    inStock: boolean,
    items: Array<Item>,
}

export const useInventory = defineStore('inventory', {
    state: () => ({
        items: [] as Array<Item>,
    }),
    getters: {
        getBag() {
            return (bagId: string): Item | undefined => this.items.find((bag) => bag.bagId === bagId);
        },
        getInStockItems() {
            type Overload = {
                (stock: boolean | undefined | 'all', grouped: true): ItemGroup[];
                (stock: boolean | undefined | 'all', grouped: false): Item[];
            }
            const stock: Overload = (stock: boolean | undefined | 'all', grouped: boolean): any => {
                const items: Item[] = stock !== 'all' ? this.items.filter((item) => item.inStock === stock) : this.items;
                if (!grouped) {
                    return items;
                }

                const groups = groupBy(items, 'productId');
                const groupKeys = Object.keys(groups);

                return groupKeys.map((key) => {
                    return {
                        productId: key,
                        composition: groups[key][0].composition,
                        color: groups[key][0].color,
                        finish: groups[key][0].finish,
                        totalItems: groups[key].length,
                        items: groups[key],
                        inStock: groups[key].some((item) => item.inStock === true),
                        totalWeight: groups[key].reduce((acc, cur) => acc + cur.weight, 0),
                        updatedAt: groups[key][0].updatedAt,
                    };
                }) as ItemGroup[];
            }

            return stock;
        },
    },
    actions: {
        async deleteBag(id: string) {
            const index = this.items.findIndex((bag) => bag.bagId === id);
            if (index !== -1) {
                this.items.splice(index, 1);
            }
            const data = {
                productId: id,
            }
            const response = await useAuthFetch('/api/product/delete', {
                method: 'DELETE',
                body: data
            });
        },
        async moveCreated(id: string) {
            const index = this.items.findIndex((bag) => bag.bagId === id);
            if (index !== -1) {
                this.items[index].inStock = undefined;
            }
            const data = {
                productId: id,
                status: "Created ID",
                weight: null
            }
            const item = await useAuthFetch('/api/product/updatestatus', {
                method: 'POST',
                body: data
            });
        },
        async moveOut(id: string) {
            const index = this.items.findIndex((bag) => bag.bagId === id);
            if (index !== -1) {
                this.items[index].inStock = false;
            }
            const data = {
                productId: id,
                status: "Moved Out",
                weight: null
            }
            const item = await useAuthFetch('/api/product/updatestatus', {
                method: 'POST',
                body: data
            });
        },
        async moveInStock(id: string, weight: number) {
            const index = this.items.findIndex((bag) => bag.bagId === id);
            if (index !== -1) {
                const bag = this.items[index];
                bag.weight = weight;
                bag.inStock = true;
            }
            const data = {
                productId: id,
                status: "In Stock",
                weight: weight
            }
            await useAuthFetch('/api/product/updatestatus', {
                method: 'POST',
                body: data
            });
        },
        async updateWeight(id: string, weight: number) {
            const index = this.items.findIndex((bag) => bag.bagId === id);
            if (index !== -1) {
                const bag = this.items[index];
                bag.weight = weight;
            }
            const data = {
                productId: id,
                weight: weight
            }
            await useAuthFetch('/api/product/updateweight', {
                method: 'POST',
                body: data
            });
        },
        async search(searchVal: string, startTs:number, endTs: number) {
            const data = {
               searchKeyword: searchVal,
               startTs: startTs,
               endTs: endTs
            }
            const items = await useAuthFetch<Array<Item>>('/api/product/search', {
                method: 'POST',
                body: data
            });

            this.items = items.map((item) => {
                item.updatedAt = new Date(item.updatedAt);
                item.inStock = item.inStock === null ? undefined : item.inStock;
                return item;
            });

        },
        async load() {
            const items = await useAuthFetch<Array<Item>>('/api/product/inventory');
            this.items = items.map((item) => {
                item.updatedAt = new Date(item.updatedAt);
                item.inStock = item.inStock === null ? undefined : item.inStock;
                return item;
            });
        }
    },
});
