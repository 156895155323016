import { defineStore } from 'pinia';
import { uniqueId } from 'lodash-es';
import dayjs from 'dayjs';

export interface Report {
    id: string,
    name: string,
    date: Date,
    status: 'complete' | 'processing' | 'partial_success' | 'error' | 'ready',
}

const statusMap:{ [id: string]: Report['status'] } = {
    "Success": "complete",
    "Error": "error",
    "Partial Success": "partial_success",
    "Pending": "processing",
    "Complete": "complete",
    "Ready": "ready"
}

export const useReports = defineStore('reports', {

    state: () => ({
        items: [] as Report[],
    }),
    actions: {
        add(opts: { name: string }) {
            this.items.unshift({
                id: uniqueId(),
                name: opts.name,
                status: 'processing',
                date: new Date(),
            });
        },
        async remove(id: string) {
            await useAuthFetch(`/api/report/delete/${id}`, {
                method: 'DELETE'
            });
            this.items.splice(this.items.findIndex(report => report.id === id), 1);
        },
        async load() {
            const items = await useAuthFetch<Array<Report>>('/api/report/list');
            this.items = items.map((item) => {
                item.date = new Date(item.date);
                item.status = statusMap[item.status];
                return item;
            });
        }
    },
})
