import * as zod from 'zod';
import { useForm } from 'vee-validate';
import { toFormValidator } from '@vee-validate/zod';

const maxErrorMessage = (value: number) => ({ message: `Cannot exceed ${value}` });
const minLengthMessage = () => ({ message: 'Must contain at least 1 character' });
const minNumberMessage = 'Cannot be less than 0';

export const schema = zod.object({
    factories: zod.number().min(0, minNumberMessage),
    employees: zod.number().min(0, minNumberMessage),
    customers: zod.array(
        zod.object({
            customer: zod.string(),
            percent: zod.number().min(0, minNumberMessage).max(100, maxErrorMessage(100)).optional(),
        })
    ).refine((value) => {
        const sum = value.reduce((acc, curr) => acc + (curr.percent ?? 0), 0);
        return sum <= 100;
    }, { message: 'Sum of percentages cannot exceed 100%' })
    .superRefine((value, ctx) => {
        for (let i = 0; i < value.length; i++) {
            if (!value[i].customer && value[i].percent) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    message: 'Required',
                    path: [i, 'customer'],
                });
            }
        }
    }),
    fabricGroups: zod.array(zod.object({
        name: zod.string(),
        fabricPurchased: zod.number().min(0, minNumberMessage),
        averageWidth: zod.number().min(0, minNumberMessage),
        averageDensity: zod.number().min(0, minNumberMessage),
        composition: zod.array(zod.object({
            fiber: zod.string().min(1, 'Required'),
            percentage: zod.number().min(0, minNumberMessage).max(100, maxErrorMessage(100)),
            elastane: zod.number().min(0, minNumberMessage).max(100, maxErrorMessage(100)),
        })).refine(value => value.reduce((a, c) => a + c.percentage, 0) === 100, 'Sum of percentages must equal 100%'),
        colors: zod.array(zod.object({
            color: zod.string().min(0, minLengthMessage()),
            percentage: zod.number().min(0, minNumberMessage).max(100, maxErrorMessage(100)),
        })).refine(value => value.reduce((a, c) => a + c.percentage, 0) === 100, 'Sum of percentages must equal 100%'),
    })).superRefine((val, ctx) => {
        if (val.length > 1) {
            for (let i = 0; i < val.length; i++) {
                if (!val[i].name) {
                    ctx.addIssue({
                        code: zod.ZodIssueCode.custom,
                        message: 'Product group name is required',
                        path: [i, 'name'],
                    });
                }
            }
        }
    }),
});

export type SurveyFormData = zod.infer<typeof schema>;

export type SurveyForm = ReturnType<typeof useSurveyForm>;

export function useSurveyForm(initial?: Partial<SurveyFormData>) {
    return useForm({
        validationSchema: toFormValidator(schema),
        initialValues: initial || {
            factories: '',
            employees: '',
            customers: [
                { customer: '', percent: undefined },
                { customer: '', percent: undefined },
                { customer: '', percent: undefined },
                { customer: '', percent: undefined },
                { customer: '', percent: undefined },
            ],
            fabricGroups: [{
                name: 'Swimwear',
                composition: [{ fiber: 'Cotton', percentage: undefined, elastane: undefined }],
                colors: [
                    { color: 'White', percentage: undefined },
                    { color: 'Black', percentage: undefined },
                    { color: 'Earth Tones', percentage: undefined },
                    { color: 'Light Tones', percentage: undefined },
                ],
                fabricPurchased: undefined,
                averageWidth: undefined,
                averageDensity: undefined,
            }],
        },
    });
}
