import { defineStore } from 'pinia';

export const useFabricComposition = defineStore('fabricComposition', {
    state: () => ({
        compositionValues: [
            'Cotton',
            'Cotton/Polyester',
            'Polyester',
            'Nylon',
            'Rayon/Viscose',
            'Wool',
        ],
    }),
    getters: {
        isValidValue(state) {
            return (value: string) => value.trim() !== '' && !state.compositionValues.includes(value);
        },
    },
    actions: {
        addValue(value: string) {
            this.compositionValues.push(value);
        },
    },
})
