import { defineStore } from 'pinia';

interface PrintHistoryItem {
    color: string,
    item: string,
    date: number,
}

export const usePrintHistory = defineStore('printHistory', {
    state: () => ({ printHistory: [] as Array<PrintHistoryItem> }),
    actions: {
        create(opts: { color: string, item: string }) {
            const index = this.printHistory
                .findIndex((item) => item.color === opts.color && item.item === opts.item);

            if (index === -1) {
                this.printHistory.push({
                    color: opts.color,
                    item: opts.item,
                    date: Date.now(),
                });
            } else {
                this.printHistory[index].date = Date.now();
            }
        },
    },
    getters: {
        sortedHistory() {
            const items: PrintHistoryItem[] = [...this.printHistory];

            return items.sort((a, b) => b.date - a.date);
        }
    },
})
