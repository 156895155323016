import { defineStore } from 'pinia';
import jwtDecode from 'jwt-decode';

function decodeToken(token: string) {
    try {
        const payload = jwtDecode<{ user: { supplierCode: string, provider: string } }>(token);
        return { supplierCode: payload.user.supplierCode, provider: payload.user.provider };
    } catch (e) {
        return { supplierCode: '' }
    }
}

export const useAuth = defineStore('auth', {
    state: () => {
        const token = localStorage.getItem('token') || '';
        const { supplierCode, provider } = decodeToken(token);

        return {
            supplierCode,
            token,
            provider
        };
    },
    getters: {
        isAuthorized(): boolean {
            return !!this.token;
        },
        getToken(): string | null {
            return this.token;
        }
    },
    actions: {
        setToken(token: string) {
            localStorage.setItem('token', token);
            this.token = token;
            this.supplierCode = decodeToken(token).supplierCode;
            this.provider = decodeToken(token).provider;
        },
        logout() {
            localStorage.removeItem('token')
            this.token = '';
        }
    },
})
