import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/supplierportal/supplierportal-web/frontend2/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/supplierportal/supplierportal-web/frontend2/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2 from "/home/supplierportal/supplierportal-web/frontend2/node_modules/nuxt/dist/head/runtime/plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/supplierportal/supplierportal-web/frontend2/node_modules/nuxt/dist/pages/runtime/router.mjs";
import _nuxt_dist_plugin_80a21418_mjs_We3MPDpJe6 from "/home/supplierportal/supplierportal-web/frontend2/.nuxt/dist.plugin.80a21418.mjs";
import plugins_svg_ts_Uew0JGO3gG from "/home/supplierportal/supplierportal-web/frontend2/plugins/svg.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  _nuxt_dist_plugin_80a21418_mjs_We3MPDpJe6,
  plugins_svg_ts_Uew0JGO3gG
]